<template>
   <div class="container">
      <div class="row justify-content-md-center">
         <div class="col-12 col-lg-8 mt-3 past-earnings-log mb-5" v-if="supportCreditData != []">

            <div class="form-group">
              <label for="exampleInputName1">Account Number</label>
              <input v-model="accountNumber" type="text" class="form-control" id="exampleInputName1" aria-describedby="nameHelp" placeholder="account number">
            </div>
            <button @click="openAccount">Open Account</button>

         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Support Admin",
   data() {
      return {
         accountNumber: ""
      }
   },
   mounted() {
   },
   methods: {
      openAccount() {
         window.location.assign(`account-admin?account=${this.accountNumber}`);
      }
   }
}
</script>